.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.logo {
  height: 100px;
  width: auto;
}

.body {
  display: flex;
  flex-grow: 2;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.buttonsHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
